nb-option-list {
  nb-option-group {
    .option-group-title {
      font-size: 0.85rem;
      font-weight: 500;
      height: 2.4rem;
      line-height: 2.5rem;
      padding: 0 1rem !important;
    }

    nb-option {
      font-size: 0.8rem !important;
      font-weight: 600;
      height: 2.2rem !important;
      line-height: 2.3rem !important;
      padding: 0 1rem 0 1.6rem !important;
      display: block !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
      white-space: nowrap !important;
    }
  }
}