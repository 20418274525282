nb-form-field {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  max-width: 45%;
  height: calc(2.6rem + 2px);
  border: 1px solid #e4e9f2 !important;

  > nb-icon,
  > .char-prefix {
    color: #a1a1a1;
    font-size: 1rem !important;
    height: 2.6rem !important;
    line-height: 2.6rem !important;
    padding: 0 5px;
    z-index: 1;
    text-align: center;
    box-sizing: content-box;
    border-right: 2px dotted rgba(0, 0, 0, 0.06);
  }

  > .char-prefix {
    font-size: 0.92rem !important;
  }

  > .char-suffix {
    position: absolute;
    flex: 1;
    right: 0;
    width: 26px;
    padding-right: 1px;
    color: unset;
    text-align: center;
    border-left: 2px dotted rgba(0, 0, 0, 0.06);
  }
  
  > input,
  > nb-select {
    background-color: #f7f9fc;
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    max-width: unset !important;
    height: 2.6rem !important;
    border: none !important;
    border-radius: 0 !important;
    font-size: 0.85rem !important;
    font-weight: 600;
    text-align: center;
    padding:
      0.4375rem
      0
      0.4375rem
      calc(1rem + 10px)
      !important;
  }

  > input::placeholder {
    font-size: 0.75rem !important;
  }

  > input.has-suffix {
    padding-right: 26px !important;
  }

  > nb-select {
    padding:
      0.4375rem
      25px
      0.4375rem
      calc(1rem + 10px)
      !important;

    > button {
      background-color: #f7f9fc;
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 2.6rem;
      font-size: 0.85rem;
      font-weight: 600;
      text-align: center !important;
      border: none !important;
      padding:
        0.4375rem
        2rem
        0.4375rem
        calc(1rem + 15px) !important;

      > nb-select-label {
        font-size: 0.85rem;
      }
        
      > nb-icon {
        width: 25px !important;
        right: 0 !important;
        text-align: center;
      }
    }
  }

  > nb-select.align-ignore-arrow {
    > button {
      padding-right: 0 !important;
    }
  }
}

nb-form-field.disabled-field {
  cursor: not-allowed;

  * {
    pointer-events: none !important;
  }

  nb-select {
    > button {
      > nb-icon {
        opacity: 0.2;
      }
    }
  }
}

nb-form-field.primary-field {
  > input {
    background-color: #0169B2;
    color: #fff;
  }

  > input::placeholder,
  > nb-icon {
    color: rgba(255, 255, 255, 0.6);
  }
}

nb-form-field.transparent-field {
  > input,
  > nb-icon,
  > nb-select {
    opacity: 0.3;
  }
}

nb-form-field.percentage-field {
  input {
    padding-left: 0 !important;
    padding-right: 21px !important
  }

  .percentage-sign {
    position: absolute;
    right: 5px;
    font-size: 0.85rem;
  }
}

nb-form-field.toggle-field {
  background-color: #f7f9fc;
  justify-content: center;

  nb-toggle {
    label {
      padding: 0 5px;
    }

    .toggle {
      width: 2.85rem;
      min-width: 2.85rem;
      height: 1.35rem;

      .toggle-switcher {
        height: 1.25rem;
        width: 1.25rem;
      }
    }

    .toggle.checked {
      .toggle-switcher {
        left: calc(
          100% -
          1.25rem -
          1px -
          0.0625rem
        );
      }
    }

    .text {
      font-size: 0.85rem;
      line-height: 1.1rem;
      max-width: calc(100% - 0.6875rem);
      max-height: 2.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      display: block;
    }
  }
}