mat-form-field {
  .mat-form-field-underline {
    background-color: #ddd;
    height: 2px;
  }

  label.mat-form-field-label {
    color: #9C9C9C;
  }

  .mat-select-value-text span {
    color: #000;
  }

  .mat-form-field-suffix {
    position: relative;
    width: 16px;
    height: 16px;

    .mat-icon-button {
      position: absolute;
      top: 2px;
      left: auto;
      right: 0;
      width: 16px;
      height: 16px;

      img {
        width: 16px;
        height: 16px;
      }
    }
    
    .mat-icon-button.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}

.mat-select-panel-wrap {
  margin: 32px 0 0 16px;
  min-width: calc(233px - 10px);
  max-width: calc(233px - 10px);

  .mat-select-panel {
    background-color: #fff;
    min-width: 100% !important;
    max-width: 100%;
    border-radius: 4px;
    box-shadow:
      0px 2px 4px rgba(0, 0, 0, 0.08),
      0px 4px 24px rgba(0, 0, 0, 0.08);

    mat-option {
      box-sizing: border-box;

      span.mat-option-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #333;
      }
    }

    mat-option:hover,
    mat-option.mat-selected {
      background-color: #EFF7FF;

      span.mat-option-text {
        color: #0169B2;
      }
    }

    mat-option:not(:last-child):hover,
    mat-option:not(:last-child).mat-selected {
      border-bottom: 1px solid #77B8FF;
    }

    mat-option:not(:last-child):not(:hover):not(.mat-selected) {
      border-bottom: 1px solid #ddd;
    }

    mat-optgroup {
      label.mat-optgroup-label {
        color: #333;
        font-size: 12px;
        line-height: 32px;
        max-height: 32px;
        border-top: 2px solid #e1e1e1;
        border-bottom: 2px solid #e1e1e1;
      }

      mat-option {
        padding-left: 16px;
      }
    }
  }
}