
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$BizPay-primary: mat-palette($mat-indigo);
$BizPay-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$BizPay-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$BizPay-theme: mat-light-theme((
  color: (
    primary: $BizPay-primary,
    accent: $BizPay-accent,
    warn: $BizPay-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($BizPay-theme);

@import 'themes';

@import '~@nebular/theme/styles/globals';
@import '~eva-icons/style/scss/eva-icons.scss';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@include nb-install() {
  @include nb-theme-global();
  
  @import './styles/cdk.scss';
  @import './styles/dialog.scss';
  @import './styles/form-field.scss';
  @import './styles/form-field-material.scss';
  @import './styles/option-list.scss';
  @import './styles/spinner.scss';
};
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; background-color: #edf1f6; }
